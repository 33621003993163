import React from 'react';
import { Box, IconButton, Typography, Link } from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import content from 'src/utils/ContentUtil';

const Followup = () => {
    return (
        <Box p={3} width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography variant="h6">{content("Follow Al Rajhi Bank", "اتبع مصرف الراجحي")}</Typography>
            <Box p={1} display="flex" flexDirection="row">
                <Link component="a" href="https://www.facebook.com/alrajhibank" target="_blank">
                    <IconButton
                        size="small"
                    >
                        <FacebookIcon />
                    </IconButton>
                </Link>
                <Link component="a" href="https://twitter.com/alrajhibank" target="_blank">
                    <IconButton
                        size="small"
                    >
                        <TwitterIcon />
                    </IconButton>
                </Link>
                <Link component="a" href="https://www.instagram.com/alrajhibank/" target="_blank">
                    <IconButton
                        size="small"
                    >
                        <InstagramIcon />
                    </IconButton>
                </Link>
                <Link component="a" href="https://www.youtube.com/alrajhibank" target="_blank">
                    <IconButton
                        size="small"
                    >
                        <YouTubeIcon />
                    </IconButton>
                </Link>
            </Box>
        </Box>
    );
}

export default Followup;