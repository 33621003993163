import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import content from 'src/utils/ContentUtil';
import Question from './Question';
import Box from '@material-ui/core/Box'
import { green } from "@material-ui/core/colors";
import Alert from '@material-ui/lab/Alert';
import Followup from './Followup';
import { labels } from 'src/labels';
import label from 'src/utils/label';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    '& .MuiMobileStepper-progress': {
      backgroundColor: green[200]
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: green[600]
    },
  }
});

export default function ProgressMobileStepper({ ro, handleSubmit, getComments, isChecked, handleChange, questions, handleBack, handleNext, activeStep, isValid, translateX, opacity, survey }) {
  const classes = useStyles();
  const styles ={
    transition: 'all 0.4s ease-in-out'
  }
  return (
    <Box width="100%" justifyItems="center" style = {{...styles, transform: `translate(${translateX}%, 0%)`, opacity: opacity}}>
      {!isValid && <Box p={2}><Alert variant="outlined" severity="error">
        {label(labels.get("please_answer_this_question"))}
      </Alert> </Box>}
      <Question survey={survey} ro={ro} getComments={getComments} isChecked={isChecked} handleChange={handleChange} question={questions[activeStep]} />
      {activeStep === questions.length - 1 && <Followup />}
      {activeStep < questions.length - 1 ?
        <Box width="100%" p={2} justifyItems="center">
          <MobileStepper
            variant="progress"
            steps={questions.length}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === questions.length - 1}>
                {label(labels.get("next"))}
                {content(<KeyboardArrowRight />, <KeyboardArrowLeft />)}
              </Button>
            }
            backButton={activeStep > 0 ?
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {content(<KeyboardArrowLeft />, <KeyboardArrowRight />)}
                {label(labels.get("back"))}
              </Button> : null
            }
          />
        </Box>
        : null}
    </Box>
  );
}
