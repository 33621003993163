import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import label from 'src/utils/label';
import Answers from './Answers';

const Question = ({ ro, getComments, isChecked, handleChange, question, survey }) => {
    return (
        <Box p={2}>
            <Typography variant="subtitle2" style={{whiteSpace: 'pre-line'}}>
                {label(question.label)}
            </Typography>
            {question.answers
                && question.answers.length > 0
                && <Box pt={3}><Answers survey={survey} ro={ro} isComments={question.comments} getComments={getComments} isChecked={isChecked} handleChange={handleChange} type={question.type} questionId={question.id} answers={question.answers} /></Box>}
        </Box>
    );
}
export default Question;