import React, { Component } from 'react';
import axios from 'axios';
import ProgressMobileStepper from './ProgressMobileStepper';
import {
    Typography,
    CardMedia,
    Box,
    Card,
    CardActions
} from '@material-ui/core';
import { labels } from 'src/labels';
import label from 'src/utils/label';
import TopBar from 'src/layouts/MainLayout/TopBar';
import CustomizedSnackbar from 'src/components/CustomizedSnackbar';

const API_URL = process.env.REACT_APP_API_BASE_URL;

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            lang: props.lang,
            languages: [],
            questions: [],
            surveyConfig: {},
            surveyId: -1,
            answers: [],
            ro: props.ro,
            status: '',
            snackbarOpen: true,
            changed: false,
            activeStep: 0,
            isValid: true,
            translateX: 0,
            opacity: 1
        };
        this.getActiveStep = this.getActiveStep.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getComments = this.getComments.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.setChanged = this.setChanged.bind(this);
        this.setActiveStep = this.setActiveStep.bind(this);
        this.setIsValid = this.setIsValid.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    componentDidMount() {
        this.fetchSurvey();
    }

    getData(v, i) {
        if (v)
            return parseInt(v.split('_')[i]);
    }

    getComments(questionId, answerId) {
        let answers = this.state.answers;
        const index = answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
        return (index >= 0) ? answers[index].comments : '';
    }

    isChecked(questionId, answerId) {
        let answers = this.state.answers;
        if(questionId === 767 && answerId !== 4416 && answers.some(item => item.questionId === questionId && item.answerId === 4416) ){
            return false;
        }
        const checked = answers.some(item => item.questionId === questionId && item.answerId === answerId);
        return checked;
    }

    fetchSurvey = async () => {
        try {
            await axios.get(API_URL + "/api/survey?token=" + this.state.token + "&ro=" + this.state.ro)
                .then(response => {
                    this.setState({ "snackbarOpen": false });
                    if (response.data) {
                        this.setState({ surveyConfig: response.data.surveyConfig, surveyId: response.data.questions && response.data.questions[0] ? response.data.questions[0].surveyId : -1, status: response.data.status, questions: response.data.questions, answers: response.data.answers, languages: response.data.languages })
                    }
                }).catch(error => {
                    console.log(JSON.stringify(error));
                    this.setState({ status: 'NOTFOUND' })
                });

        } catch (err) {
            console.error(err);
        }
    }

    getActiveStep = (prevActiveStep, isNext) => {
        let activeStep = isNext ? prevActiveStep + 1 : prevActiveStep - 1;
        let question = this.state.questions[activeStep];
        if (question.skipLogicEnabled && question.skipLogic && question.skipLogic.length > 0) {
            let skipLogic = question.skipLogic[0];
            let conditionQuestionId = skipLogic.conditionQuestionId;
            let conditionAnswerIds = skipLogic.conditionAnswerIds.split(",").map(Number);
            let answers = this.state.answers;
            let isMatch = false;
            conditionAnswerIds.forEach(function (conditionAnswerId) {
                if (isMatch) return;
                isMatch = answers.some(item => item.questionId === conditionQuestionId && item.answerId === parseInt(conditionAnswerId));
            });
            console.log('checkActiveStep::isMatch: ' + isMatch);
            if (!isMatch) {
                return this.getActiveStep(activeStep, isNext);
            }
        }
        return activeStep;
    }

    validate = (questionId, isRequired) => {
        if (this.state.ro || !isRequired)
            return true;
        let answers = this.state.answers;
        let isValid = answers.some(item => item.questionId === questionId);
        if (!isValid)
            window.scrollTo(0, 0);
        return isValid;
    }

    handleSubmit = async (questionId, isCompleted) => {
        try {
            if (this.state.ro)
                return;
            if (!this.state.changed)
                return;
            if (!this.validate(questionId))
                return;
            await axios.post(API_URL + "/api/survey/response", { "token": this.state.token.split('&')[0], "questionId": questionId, "isCompleted": isCompleted, "answers": this.state.answers })
                .then(response => {
                    if (response.data) {
                        console.log(JSON.stringify(response.data));
                    }
                }).catch(error => {
                    console.log(JSON.stringify(error))
                });
        } catch (err) {
            console.error(err);
        }
    }

    setChanged(status) {
        this.setState({ changed: status });
    }

    setActiveStep(activeStep) {
        this.setState({ activeStep: activeStep });
    }

    setIsValid(isValid) {
        this.setState({ isValid: isValid });
    }

    OnTransform (val, op) {
        this.setState({
            translateX: val,
            opacity: op
        })
        if(val ==-100) setTimeout(() => {  this.OnTransform(+100, 0); }, 400);
    }

    handleNext = () => {
        let isCompleted = (this.state.activeStep === this.state.questions.length - 2) ? true : false;
        let questionId = this.state.questions[this.state.activeStep].id;
        let isRequired = this.state.questions[this.state.activeStep].required;
        if (this.validate(questionId, isRequired)) {
            this.setIsValid(true);
            this.handleSubmit(questionId, isCompleted);
            this.setChanged(false);
            this.setState((prevState) => ({ activeStep: this.getActiveStep(prevState.activeStep, true) }));
        } else {
            this.setIsValid(false);
        }
    };

    handleBack = () => {
        let isCompleted = (this.state.activeStep === this.state.questions.length - 2) ? true : false;
        let questionId = this.state.questions[this.state.activeStep].id;
        this.handleSubmit(questionId, isCompleted);
        this.setIsValid(true);
        this.setChanged(false);
        this.setState((prevState) => ({ activeStep: this.getActiveStep(prevState.activeStep, false) }));
    };

    handleChange(event, v, qId) {
        this.setChanged(true);
        let value = event.target.value;
        let id = event.target.id;
        let type = event.target.type;
        type = (type == null || type === 'button') ? 'radio' : type;
        value = (value == null) ? v : value;
        let questionId = (type === 'textarea') ? this.getData(id, 0) : this.getData(value, 0);
        let answerId = (type === 'textarea') ? this.getData(id, 1) : this.getData(value, 1);
        let question = this.state.questions.find(q => q.id === questionId);

        let comments = '';
        let _answers = this.state.answers;

        if (type === 'radio' && questionId == null) {
            const index = _answers.findIndex((item => item.questionId === qId))
            index >= 0 && _answers.splice(index, 1);
            this.setState({ answers: _answers });
            return;
        }

        if (type === 'radio') {
            const index = _answers.findIndex((item => item.questionId === questionId))
            index >= 0 && _answers.splice(index, 1);
        }
        if (type === 'textarea') {
            const index = _answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
            index >= 0 && _answers.splice(index, 1);
            comments = value;
        }
        if (type === 'checkbox' && event.target.checked === false) {
            const index = _answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
            index >= 0 && _answers.splice(index, 1);
            this.setState({ answers: _answers });
            return;
        }

         // customize disabled button code for survey 89
         if(questionId === 767 && answerId === 4416 && _answers.some(item => item.questionId === questionId && item.answerId !== 4416) ){
            let list = _answers.filter(item => item.questionId === 767 && item.answerId !== 4416)
            list.forEach( l => {
                const index = _answers.findIndex((item => item.questionId === 767 && item.answerId === l.answerId))
                index >= 0 && _answers.splice(index, 1);
            })
        }

        let _answer = { 'questionId': questionId, 'answerId': answerId, 'comments': comments }
        _answers.push(_answer);
        this.setState({ answers: _answers });
        if (!question.comments && (type === 'radio' || question.type === "RADIO" || type === 'smiley' || question.type === "SMILEY" || type === 'nps' || question.type === "NPS")) {
            setTimeout(() => {  this.OnTransform(-100, 0); }, 300);
            setTimeout(() => {  this.handleNext();this.OnTransform(0, 1); }, 950);
        }
    }
    render() {
        return (
            <>
                <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                    <TopBar token={this.state.token} lang={this.state.lang} languages={this.state.languages} />
                    <Card>
                        {
                            this.state.surveyConfig && this.state.surveyConfig.banner && <CardMedia component="img" image={`static/images/banner/${this.state.surveyConfig.banner}`}></CardMedia>
                        }
                        <CardActions>
                            {
                                this.state.status === 'VALID' && this.state.questions && this.state.questions.length > 0 &&
                                <ProgressMobileStepper ro={this.state.ro} survey={this.state.surveyId} getActiveStep={this.getActiveStep} validate={this.validate} handleSubmit={this.handleSubmit} getComments={this.getComments} isChecked={this.isChecked} handleChange={this.handleChange} questions={this.state.questions} activeStep={this.state.activeStep} isValid={this.state.isValid} handleBack={this.handleBack} handleNext={this.handleNext} opacity={this.state.opacity} translateX = {this.state.translateX} />
                            }
                            {
                                this.state.status === 'EXPIRED' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                                    {label(labels.get("survey_expired"))}
                                </Typography>
                            }
                            {
                                this.state.status === 'COMPLETED' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                                    {label(labels.get("survey_completed"))}
                                </Typography>
                            }
                            {
                                this.state.status === 'NOTFOUND' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                                    {label(labels.get("survey_notfound"))}
                                </Typography>
                            }
                            {
                                this.state.status === 'INVALID' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                                    {label(labels.get("survey_notfound"))}
                                </Typography>
                            }
                        </CardActions>
                    </Card>
                </Box>
                <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                    <CustomizedSnackbar
                        open={this.state.snackbarOpen}
                    />
                </Box>
            </>
        );
    }
}

export default Survey;