import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import Logo from 'src/components/Logo';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    height: '4em'
  },
  listItem: {
    borderRadius: "0.25em",
    maxHeight: '2em',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  }
}));


const TopBar = ({ languages, lang, token, className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLang, setSelectedLang] = React.useState(lang);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, lang) => {
    setSelectedLang(lang);
    setAnchorEl(null);
    navigate('/' + token + '?l=' + lang, { replace: true });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar
      style={{ background: '#221afb' }}
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      < Toolbar className={classes.toolbar}>
        <RouterLink to="">
          <Logo />
        </RouterLink>
        {languages && languages.length > 1 &&
          <>
            <Box flexGrow={1} />
            <Box>
              <List component="nav">
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={handleClickListItem}
                >
                  <ListItemText secondary={<Typography variant="body1">{languages.filter(language => language.code.includes(selectedLang)).map(obj => obj.label)}</Typography>} />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {languages.map((language, index) => (
                  <MenuItem
                    key={index}
                    selected={language.code === selectedLang}
                    onClick={(event) => handleMenuItemClick(event, language.code)}
                  >
                    {language.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </>
        }
      </Toolbar>

    </AppBar >
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
