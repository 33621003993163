import React, { useEffect, useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import label from 'src/utils/label';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { green } from "@material-ui/core/colors";
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& label.Mui-focused': {
            color: green[600],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: green[400],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: green[400],
            },
            '&:hover fieldset': {
                borderColor: green[400],
            },
            '&.Mui-focused fieldset': {
                borderColor: green[600],
            },
        },
    }
}));

const TextArea = ({ ro, getComments, handleChange, questionId, answer, isChecked }) => {
    console.log(JSON.stringify(answer));
    const [count, setCount] = React.useState(0);
    const classes = useStyles();
    const [value, setValue] = useState('');
    const handleOnChange = useCallback(e => {
        setCount(e.target.value.length);
        setValue(e.target.value);
        handleChange(e);
    }, [handleChange]);
    useEffect(() => {
        setValue(getComments(questionId, answer.id));
        setCount(getComments(questionId, answer.id).length);
    }, [getComments, questionId, answer]);
    return (
        <>
        <FormControlLabel
            className={classes.root}
            control={<TextField
                fullWidth
                inputProps={{
                    maxLength: 250,
                  }}
                label={label(answer.label)}
                id={questionId + '_' + answer.id}
                multiline rows={4}
                variant="outlined"
            />}
            value={value}
            onChange={handleOnChange}
            disabled={JSON.parse(ro) || isChecked(questionId, 4416)}
        />
        <Typography align="right" style={{marginRight: '25px'}}>
            {count + " /250"}
        </Typography>
        </>
    )
}


export default TextArea;